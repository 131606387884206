import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import "../styles/solution.scss";

function Solution() {
  return (
    <Layout>
      <SEO title="Solution" />
      <div className="page-title-area">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <h2>Services Details</h2>
                </div>
            </div>
        </div>
        <div className="shape1"><img src={require("../images/shape1.png")} alt="shape" /></div>
        <div className="shape2 rotateme"><img src={require("../images/shape2.svg")} alt="shape" /></div>
        <div className="shape3"><img src={require("../images/shape3.svg")} alt="shape" /></div>
        <div className="shape4"><img src={require("../images/shape4.svg")} alt="shape" /></div>
        <div className="shape5"><img src={require("../images/shape5.png")} alt="shape" /></div>
        <div className="shape6 rotateme"><img src={require("../images/shape4.svg")} alt="shape" /></div>
        <div className="shape7"><img src={require("../images/shape4.svg")} alt="shape" /></div>
        <div className="shape8 rotateme"><img src={require("../images/shape2.svg")} alt="shape" /></div>
      </div>
    </Layout>
  )
}

export default Solution;