import React from 'react';
import { Preloader, Placeholder } from 'react-preloading-screen';

import '../styles/maintainance.scss';

const Maintainance = () => (
  <Preloader>
      <Placeholder>
          <div className="preloader">
              <div className="spinner"></div>
          </div>
      </Placeholder>
      <section className="coming-soon-area">
          <div className="d-table">
              <div className="d-table-cell">
                  <div className="container">
                      <div className="coming-soon-content">
                          <h1>Under Construction</h1>
                          <p>Our website is currently undergoing scheduled maintenance. We Should be back shortly. Thank you for your patience.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </Preloader>
)

export default Maintainance;

