import React,  { useState, useEffect }  from "react";
import { Link } from "gatsby";

import '../styles/navbar.scss';

function Header() {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    let elementId = document.getElementById("navbar");
      document.addEventListener("scroll", () => {
        if (window.scrollY > 170) {
          elementId.classList.add("is-sticky");
      } else {
          elementId.classList.remove("is-sticky");
      }
    })
    window.scrollTo(0, 0);
  }, []);

  function navbarToggle() {
    setCollapsed(!collapsed);
  }

  const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
  const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

  return(
    <header id="header">
      <div id="navbar" className="startp-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link to="/" className="navbar-brand" onClick={() => window.location.refresh()}>
              <img src={require("../images/logo.png")} alt="logo" />
            </Link>
            <button 
              onClick={navbarToggle} 
              className={classTwo}
              type="button" 
              data-toggle="collapse" 
              data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
              aria-expanded="false" 
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav nav ml-auto">
                <li className="nav-item">
                  <Link activeClassName="active" to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link activeClassName="active" to="/solution" className="nav-link">
                    Our Solution
                  </Link>
                </li>
                <li className="nav-item">
                  <div className="nav-dropdown">
                    Industries
                  </div>
                </li>
                <li className="nav-item">
                  <Link activeClassName="active" to="/news" className="nav-link">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link activeClassName="active" to="/contact" className="nav-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div> 
      </div>
    </header>
  )
}

export default Header;
